import { useEffect, useState } from "react";
import zxcvbn from "zxcvbn";
import { SubmissionState } from "../../types";
import "./PasswordChange.scss";

const MIN_SCORE = 3;
const MIN_CHARS = 8;

export interface PasswordChangeParams {
  submissionState: SubmissionState;
  submitNewPassword: (password: string) => void;
  submitError: string | null;
}

function PasswordChange(params: PasswordChangeParams) {
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [zxcvbnResult, setZxcvbnResult] = useState<zxcvbn.ZXCVBNResult | null>(null);
  const [passwordHeadlineError, setPasswordHeadlineError] = useState<string | null>(null);
  const [passwordConfirmationError, setPasswordConfirmationError] = useState<string | null>(null);

  function setNewPassword(password: string) {
    setNewPassword1(password);
    const zr = zxcvbn(password);
    setZxcvbnResult(zr);

    if (password.length < MIN_CHARS) {
      setPasswordHeadlineError(`Please enter at least ${MIN_CHARS} characters`);
    } else if (zr.score < MIN_SCORE) {
      setPasswordHeadlineError("Password is not secure enough");
    } else if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)/.test(password)) {
      setPasswordHeadlineError("Password must contain at least one uppercase letter, one lowercase letter, one number, and one symbol.");
    } else {
      setPasswordHeadlineError(null);
    }
  }

  useEffect(() => setNewPassword(""), []);

  function handlePasswordConfirmation(password: string) {
    setNewPassword2(password);
    setPasswordConfirmationError(newPassword1 !== password ? "Passwords do not match" : null);
  }

  return (
    <div className="PasswordChange">
      <h1>Reset your password</h1>
      <p>Thanks for confirming your email address. You can now reset your password below.</p>

      <label htmlFor="newPassword1">New password</label>
      <input type="password" id="newPassword1" value={newPassword1} onChange={(event) => setNewPassword(event.target.value)} />

      {newPassword1.length < MIN_CHARS && (
        <p className="error">Please enter at least {MIN_CHARS} characters</p>
      )}

      {newPassword1.length >= MIN_CHARS && (
        <>
          {passwordHeadlineError && <p className="error">{passwordHeadlineError}</p>}
          {!passwordHeadlineError && <p className="ok">Strong password</p>}

          {zxcvbnResult?.feedback?.warning && <p className="warn">{zxcvbnResult.feedback.warning}</p>}

          {!!zxcvbnResult?.feedback?.suggestions?.length && (
            <ul className="suggestions">
              {zxcvbnResult.feedback.suggestions.map((suggestion) => (
                <li>{suggestion}</li>
              ))}
            </ul>
          )}
        </>
      )}

      <label htmlFor="newPassword2">Confirm password</label>
      <input
        type="password"
        id="newPassword2"
        value={newPassword2}
        onChange={(event) => handlePasswordConfirmation(event.target.value)}
      />
      {passwordConfirmationError && <p className="error">{passwordConfirmationError}</p>}

      <button
        disabled={
          params.submissionState !== SubmissionState.NOT_SUBMITTING ||
          !!passwordHeadlineError ||
          !!passwordConfirmationError ||
          newPassword1 !== newPassword2
        }
        onClick={() => params.submitNewPassword(newPassword1)}
      >
        {params.submissionState === SubmissionState.SUBMITTING ? "Submitting..." : "Submit"}
      </button>
      {params.submitError && <p className="error">{params.submitError}</p>}
    </div>
  );
}

export default PasswordChange;


