import ok from "../../res/ok.svg";
import "./VerifyEmailSuccess.scss"

function PasswordChangeSuccess() {
    return (<>
        <img src={ ok } alt="" />
        <h1>Your email has been verified</h1>
        <p>You can now close this window.</p>
    </>);
}

export default PasswordChangeSuccess;